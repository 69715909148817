* {
    font-family: Poppins, sans-serif !important;
}

.arabic-font {
    font-family: 'Amiri', serif !important;
    text-align: right !important;
}

.f-font-md {
    font-size: 2em;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.main-page {
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.main-color {
    background: rgb(46, 96, 124);
    background: linear-gradient(90deg, #0470be 0%, #016a63 54%);
}

.main-green {
    background: rgb(46, 96, 124);
    background: linear-gradient(90deg, #2bccaf 0%, #40e597 54%);
}

.main-purple {
    background: rgb(46, 96, 124);
    background: linear-gradient(90deg, #a357fd 0%, #db6adf 54%);
}

.main-blue {
    background: rgb(46, 96, 124);
    background: linear-gradient(90deg, #8776f7 0%, #5bc5f7 54%);
}

.main-orange {
    background: rgb(46, 96, 124);
    background: linear-gradient(90deg, #feab3c 0%, #f88497 54%);
}

.pointer {
    cursor: pointer;
}

.v-text-field__details {
    padding: 0px !important;
}

.d-none {
    display: none;
}

.clickabale {
    cursor: pointer;
}